import React from 'react';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';

import Layout from '../components/Layout';
import Content, { HTMLContent, ContentType } from '../components/Content';
import Container from 'react-bootstrap/Container';

export const BlogPostTemplate = ({
  content,
  contentComponent,
  tags,
  title,
  date,
  helmet,
}: BlogPostTemplateType) => {
  const PostContent = contentComponent || Content;

  return (
    <Container className="blog-post">
      {helmet || ''}
      <h1>{title}</h1>
      <p>
        <i className="fas fa-calendar-day"></i> {date}<br />
        {tags && tags.length ? (
          <>
            {tags.map((tag, idx) => (
              <span key={`tags-${idx}`} className="tag-element">
                <Link to={`/tags/${kebabCase(tag)}/`}><i className="fas fa-tag"></i>&nbsp;{tag}</Link>
              </span>
            ))}
          </>
        ) : null}
      </p>
      <PostContent content={content} />
      <Link to="/blog" className="btn btn-primary btn-blog-overview"><i className="fas fa-long-arrow-alt-left"></i> Zurück zu allen Blog-Beiträgen</Link>
    </Container>
  );
};

type BlogPostTemplateType = {
  content: string,
  contentComponent?: { (data: ContentType): any },
  title: string,
  date: string,
  helmet?: any,
  tags: Array<string>,
};

const BlogPost = ({ data }: BlogPostType) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="Blog | %s">
            <title>{`${post.frontmatter.title}`}</title>
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
      />
    </Layout>
  );
};

type BlogPostType = {
  data: {
    markdownRemark: {
      id: string,
      html: string,
      frontmatter: {
        date: string,
        title: string,
        tags: Array<string>,
      },
    },
  },
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "DD. MMMM YYYY", locale: "de")
        title
        tags
      }
    }
  }
`;
